import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import Toggle from "../Toggle/Toggle";
import sun from "../../../../content/assets/sun.png";
import moon from "../../../../content/assets/moon.png";

class MyThemeToggler extends React.Component {
  render() {
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <Toggle
            icons={{
              checked: (
                <img
                  src={moon}
                  width="16"
                  height="16"
                  role="presentation"
                  style={{ pointerEvents: "none" }}
                />
              ),
              unchecked: (
                <img
                  src={sun}
                  width="16"
                  height="16"
                  role="presentation"
                  style={{ pointerEvents: "none" }}
                />
              ),
            }}
            checked={theme === "dark"}
            onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
          />
        )}
      </ThemeToggler>
    );
  }
}

export default MyThemeToggler;
