import React from "react";
import { Link } from "gatsby";
import { rhythm } from "../../utils/typography";
import { Header, ThemeToggle } from "../common";

const TitleLink = ({ children }) => (
  <Link
    style={{
      boxShadow: `none`,
      color: `inherit`,
    }}
    to={`/`}
  >
    {children}
  </Link>
);

const Layout = ({ location, title, children }) => (
  <div
    style={{
      backgroundColor: "var(--bg)",
      color: "var(--textNormal)",
      // transition: 'color 0.2s ease-out, background 0.2s ease-out',
      marginLeft: `auto`,
      marginRight: `auto`,
      maxWidth: rhythm(24),
      padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
    }}
  >
    <header>
      <table>
        <tbody>
          <tr>
            <td>
              <Header isRootPath={location.pathname === `${__PATH_PREFIX__}/`}>
                <TitleLink>{title}</TitleLink>
              </Header>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
              <ThemeToggle />
            </td>
          </tr>
        </tbody>
      </table>
    </header>
    <main>{children}</main>
    <footer style={{ marginTop: "50px" }}>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </footer>
  </div>
);

export default Layout;
