import React from "react";

const Header = ({ isRootPath, children }) => {
  return isRootPath ? (
    <h1
      style={{
        // ...scale(1.1),
        marginBottom: 0, // rhythm(1.5),
        marginTop: 0,
      }}
    >
      {children}
    </h1>
  ) : (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      {children}
    </h3>
  );
};

export default Header;
